import Helmet from 'react-helmet'
import React from 'react';
import Moment from 'moment';
import {Link} from 'gatsby';
import {Title, Content} from 'components/post';
import Post from 'components/post';
import Layout from 'layouts';

export default (props) => {
  console.log(props.pageContext);
  return (
    <Layout {...props} >
      <Helmet title={`Blog | ${props.pageContext.frontmatter.title}`} />
      <Post post={props.pageContext} spacer path="approach" />
    </Layout>
  )
}
